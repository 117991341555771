import React, { useEffect, useState } from "react"

import styles from './BlogAuthor.module.scss'

const BlogAuthor = ({ author = {} }) => {
  const { name, description, photoUrl, position } = author
  const [isMobile, setIsMobile] = useState(false)
  const handleWindowSizeChange = () => {
    setIsMobile(window.innerWidth <= 640)
  }
  useEffect(() => {
    handleWindowSizeChange()
    window.addEventListener('resize', handleWindowSizeChange)
  }, [])
  return (
    <aside className={styles.Container}>
      <div>
        <h2 className={styles.Name}>
          { name }
          <br />
          <span className={styles.Position}>
            { position }
          </span>
        </h2>
        {
          isMobile &&
          <img
            className={styles.Picture}
            src={photoUrl}
            alt={name}
          />
        }
        <p className={styles.Description}>{ description }</p>
      </div>
      {
        !isMobile &&
        <img
          className={styles.Picture}
          src={photoUrl}
          alt={name}
        />
      }
    </aside>
  )
}

export default BlogAuthor

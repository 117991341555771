import React, { forwardRef } from "react"
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share';
import root from 'window-or-global'
import get from 'lodash/get'

import styles from './ShareButtons.module.scss'

const ShareButtons = forwardRef((props, ref) => {
  const url = get(root, 'location.href')
  return (
    <div ref={ref} className={styles.ButtonsWrapper}>
      <FacebookShareButton
        className={`${styles.ShareButton} ${styles.Facebook}`}
        url={url}
      >
        <FacebookIcon
          size={32}
        />
        <span className={styles.ButtonText}>
          {'Share'}
        </span>
      </FacebookShareButton>
      <TwitterShareButton
        className={`${styles.ShareButton} ${styles.Twitter}`}
        url={url}
      >
        <TwitterIcon
          size={32}
        />
        <span className={styles.ButtonText}>
          {'Tweet'}
        </span>
      </TwitterShareButton>
      <LinkedinShareButton
        className={`${styles.ShareButton} ${styles.Linkedin}`}
        url={url}
      >
        <LinkedinIcon
          size={32}
        />
        <span className={styles.ButtonText}>
          {'Share'}
        </span>
      </LinkedinShareButton>
    </div>
  )
})

export default ShareButtons

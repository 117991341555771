import React from "react"
import { graphql } from "gatsby"
import get from 'lodash/get'

import BlogLayout from "../layouts/BlogLayout/BlogLayout"
import BlogPostView from "../views/BlogPostView/BlogPostView"
import { CONTENT_ITEMS_TYPE } from '../utils/consts'

const BlogArticlePage = (props) => {
  const mainPath = 'data.allKontentItemBlogArticle.nodes[0].elements'
  const title = get(props, `${mainPath}.title.value`, '')
  const content = get(props, `${mainPath}.content.value`, '')
  const publicationDate = get(props, `${mainPath}.publication_date.value`, '')
  const blogCategory = get(props, `${mainPath}.blog_category.linked_items[0].elements.blog_category_name.value`)

  const links = get(props, `${mainPath}.content.linked_items`, []).map(link => {
    const type = get(link, '__typename')
    switch (type) {
      case CONTENT_ITEMS_TYPE.LINKED_IMAGE:
        return ({
          linkTo: get(link, 'elements.link_to.value'),
          imgUrl: get(link, 'elements.image.value[0].url'),
          alt: get(link, 'elements.image.value[0].name'),
          codeName: get(link, 'system.codename'),
          type,
        })
      case CONTENT_ITEMS_TYPE.VIDEO_CONTENT:
        return ({
          src: get(link, 'elements.video_url.value'),
          codeName: get(link, 'system.codename'),
          type,
        })
      case CONTENT_ITEMS_TYPE.DOWNLOAD_SECTION:
        return ({
          title: get(link, 'elements.title.value'),
          lead: get(link, 'elements.lead.value'),
          icon: get(link, 'elements.icon.value[0].url'),
          alt: get(link, 'elements.icon.value[0].name'),
          buttonUrl: get(link, 'elements.button.linked_items[0].elements.slug_to_page.value'),
          buttonColor: get(link, 'elements.button.linked_items[0].elements.button_color__button_color.value[0].name'),
          buttonSize: get(link, 'elements.button.linked_items[0].elements.button_size__button_size.value[0].name'),
          buttonImageUrl: get(link, 'elements.button.linked_items[0].elements.image.value[0].url'),
          buttonText: get(link, 'elements.button.linked_items[0].elements.name.value'),
          codeName: get(link, 'system.codename'),
          type
        })
      default:
        return {}
    }
  })

  const author = {
    name: get(props, `${mainPath}.author.linked_items[0].elements.person_data.linked_items[0].elements.name_and_surname.value`, ''),
    position: get(props, `${mainPath}.author.linked_items[0].elements.person_data.linked_items[0].elements.person_position.value`, ''),
    photoUrl: get(props, `${mainPath}.author.linked_items[0].elements.person_data.linked_items[0].elements.person_picture.value[0].url`, ''),
    description: get(props, `${mainPath}.author.linked_items[0].elements.author_description.value`, ''),
  }

  const seo = {
    metaTitle: get(props, `${mainPath}.basic_page_settings__title.value`, ''),
    metaDescription: get(props, `${mainPath}.seo__meta_description.value`, ''),
    metaImage: get(props, `${mainPath}.seo__meta_image.value[0].url`),
  }

  const postDetails = {
    title,
    content,
    publicationDate,
    blogCategory,
    author,
    links,
    seo,
  }

  const relatedPosts = get(props, 'data.relatedPosts.nodes', [])

  return (
    <BlogLayout location={props.location}>
      <BlogPostView
        postDetails={postDetails}
        relatedPosts={relatedPosts}
      />
    </BlogLayout>
  )
}
export default BlogArticlePage

export const query = graphql`
  query ($postId: String, $language: String) {
    allKontentItemBlogArticle(filter: {preferred_language: {eq: $language}, id: {eq: $postId}}) {
      nodes {
        elements {
          title {
            value
          }
          basic_page_settings__title {
            value
          }
          seo__meta_description {
            value
          }
          seo__meta_image {
            value {
              url
            }
          }
          content {
            linked_items {
              ... on KontentItemLinkedImage {
                id
                elements {
                  link_to {
                    value
                  }
                  image {
                    value {
                      url
                      name
                    }
                  }
                }
                system {
                  codename
                }
              }
              ... on KontentItemVideoContent {
                id
                elements {
                  video_url {
                    value
                  }
                }
                system {
                  codename
                }
              }
              ... on KontentItemEbookDownloadSection {
                elements {
                  button {
                    linked_items {
                      id
                      elements {
                        button_color__button_color {
                          value {
                            name
                          }
                        }
                        button_size__button_size {
                          value {
                            name
                          }
                        }
                        image {
                          value {
                            url
                            name
                          }
                        }
                        name {
                          value
                        }
                        slug_to_page {
                          value
                        }
                      }
                    }
                  }
                  icon {
                    value {
                      url
                      name
                    }
                  }
                  lead {
                    value
                  }
                  title {
                    value
                  }
                }
                id
                system {
                  codename
                }
              }
            }
            value
          }
          blog_category {
            linked_items {
              elements {
                blog_category_name {
                  value
                }
              }
            }
          }
          author {
            linked_items {
              elements {
                author_description {
                  value
                }
                person_data {
                  linked_items {
                    elements {
                      name_and_surname {
                        value
                      }
                      person_picture {
                        value {
                          url
                        }
                      }
                      person_position {
                        value
                      }
                    }
                  }
                }
              }
            }
          }
          basic_page_settings__url {
            value
          }
          publication_date {
            value(formatString: "DD.MM.YYYY")
          }
        }
        system {
          language
        }
        id
        preferred_language
      }
    }
    relatedPosts: allKontentItemBlogArticle(filter: {system: {language: {eq: $language}}, id: {ne: $postId}}, limit: 3) {
      nodes {
        elements {
          title {
            value
          }
          summary {
            value
          }
          image {
            value {
              url
            }
          }
          blog_category {
            linked_items {
              elements {
                blog_category_name {
                  value
                }
              }
            }
          }
          basic_page_settings__url {
            value
          }
        }
      }
    }
  }
`

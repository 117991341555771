import React, { useRef, useState, useEffect } from "react"
import get from 'lodash/get'
import parse, { domToReact } from 'html-react-parser'
import { Link } from "gatsby"

import BlogAuthor from "../../components/containers/BlogAuthor/BlogAuthor"
import PostsList from "../../components/containers/PostsList/PostsList"
import ShareButtons from "../../components/containers/ShareButtons/ShareButtons"
import PostCategoryBadge from "../../components/blocks/PostCategoryBadge/PostCategoryBadge"
import Button from "../../components/blocks/Button/Button"
import { CONTENT_ITEMS_TYPE } from '../../utils/consts'

import styles from './BlogPostView.module.scss'

const P = ({ children }) => <p className={styles.Text}> {children} </p>

const H1 = ({ children }) =>
  <h1 className={styles.HeaderExtraLarge}> {children} </h1>

const H2 = ({ children }) => (
  <h2 className={styles.HeaderLarge}> {children} </h2>
)

const H3 = ({ children }) => (
  <h3 className={styles.HeaderRegular}> {children} </h3>
)

const EM = ({ children }) => <i>{children[1] || children}</i>

const STRONG = ({ children }) => <strong>{children[1] || children}</strong>

const UL = ({ children }) => (
  <ul className={styles.List}>{children}</ul>
)

const OL = ({ children }) => (
  <ol className={styles.List}>{children}</ol>
)

const LI = ({ children }) => (
  <li className={styles.ListItem}>
    <span>{children}</span>
  </li>
)

const A = (props) => {
  return (
    <a className={styles.ContentLink} {...props.attribs} target={"_blank"} rel="noopener noreferrer">
      {props.children[1] || props.children}
    </a>
  )
}

const IMG = (props) =>
  <img className={styles.Image} {...props.attribs} alt=""/>

const OBJECT = props => {
  const codeName = get(props, 'attribs.data-codename')
  const link = get(props, 'links', []).find(link => link.codeName === codeName) || {}
  switch (link.type) {
    case CONTENT_ITEMS_TYPE.LINKED_IMAGE:
      return (
        <a
          className={styles.LinkWrapper}
          href={link.linkTo}
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className={styles.LinkImg}
            src={link.imgUrl}
            alt={link.alt}
          />
        </a>
      )
    case CONTENT_ITEMS_TYPE.VIDEO_CONTENT:
      return (
        <iframe
          src={link.src}
          width='100%'
          height={calculateIframeHeight()}
          allowFullScreen={true}
          frameBorder='0'
          title={link.alt}
        />
      )
    case CONTENT_ITEMS_TYPE.DOWNLOAD_SECTION:
      return (
        <div className={styles.DownloadSection}>
          <span className={styles.DownloadTitle}>{link.title}<img className={styles.BookIcon} src={link.icon} alt={link.name} /></span>
          <h2 className={styles.DownloadLead}>{link.lead}</h2>
          <Button 
            color={link.buttonColor} 
            name={link.buttonText}
            image={link.buttonImageUrl} 
            size={link.buttonSize} 
            slug={link.buttonUrl}
            openInNewTab={true} />
        </div>
      )
    default:
      return null
  }
}

const componentsMapping = {
  a: A,
  p: P,
  h1: H1,
  h2: H2,
  h3: H3,
  li: LI,
  ul: UL,
  ol: OL,
  em: EM,
  img: IMG,
  strong: STRONG,
  object: OBJECT,
}

const calculateIframeHeight = () => {
  if (typeof window !== 'undefined') {
    if (window.innerWidth < 640) return '230px';
    if (window.innerWidth < 1100) return '370px';
  }
  return '500px';
}


const options = (props = []) => ({
  replace: (domNode) => {
    if (domNode.name in componentsMapping) {
      const T = componentsMapping[domNode.name];
      if (!T) {
        return;
      }

      return (
        <T attribs={domNode.attribs} links={props}>
          {' '}
          {domToReact(domNode.children, options())}{' '}
        </T>
      );
    }
    return;
  }
})

const BlogPostView = ({ postDetails, relatedPosts }) => {
  const { title, content = '', author = {}, blogCategory, publicationDate, links = [] } = postDetails || {}

  const [height, setHeight] = useState(0)

  const ref = useRef(null)

  useEffect(() => {
    setHeight(get(ref, 'current.clientHeight', 0))
  }, [ref])

  return (
    <>
      <div className={styles.PostContainer}>
        <div>
          <Link
            className={styles.Link}
            to='/blog'
            state={{ blogCategory }}
          >
            <PostCategoryBadge category={blogCategory} />
          </Link>
          <span className={styles.PublicationDate}>{publicationDate}</span>
        </div>
        <h1 className={styles.HeaderExtraLarge}>{title}</h1>
        <div className={styles.ContentWrapper}>
          <div style={{ height: `${height}px` }} className={styles.SocialMediaWrapper}>
            <ShareButtons ref={ref} />
          </div>
          <div className={styles.BlogPostContent}>
            {parse(content, options(links))}
          </div>
        </div>
        <div className={styles.AuthorWrapper}>
          <BlogAuthor author={author} />
        </div>
      </div>
      <div className={styles.PostListWrapper}>
        <PostsList blogPosts={relatedPosts} />
      </div>
    </>
  )
}

export default BlogPostView
